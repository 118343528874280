<template>
  <div>
    <b-switch
      :value="isInternal"
      class="is-pulled-right"
      size="is-small"
      @input="setInternalTransfer"
    >
      Internal transfer
    </b-switch>
    <div class="is-size-7 mb-4">
      {{ tx.description }}
    </div>
    <b-field
      v-if="!isInternal"
      label="Related to order:"
    >
      <AutocompleteOrder v-model="orderId" />
      <b-button
        v-if="order"
        size="is-small"
        class="ml-4 mt-1"
        @click="updateFromOrder"
      >
        Update order details
      </b-button>
    </b-field>
    <template v-if="!isInternal">
      <div
        v-for="(item, i) in localValue"
        :key="item.id"
        class="columns"
      >
        <div class="column is-narrow">
          <b-field
            label="Amount"
            style="width: 100px"
            :message="usdAmountMessage(localValue[i].amount)"
          >
            <b-input
              ref="amount"
              v-model="localValue[i].amount"
              :disabled="i === 0"
              @input="updateLeftover"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="Description">
            <b-input v-model="localValue[i].description" />
          </b-field>
          <div class="columns">
            <div class="column">
              <AutocompleteProduct
                v-model="localValue[i].productId"
                size="is-small"
                @input="updateProductFamily(i)"
              />
            </div>
            <div
              v-if="!item.productId"
              class="column"
            >
              <AutocompleteProductFamily
                v-model="localValue[i].productFamilyId"
                size="is-small"
              />
            </div>
          </div>
          <b-field v-show="!item.productFamilyId">
            <b-radio-button
              v-model="localValue[i].category"
              :native-value="null"
              size="is-small"
            >
              None
            </b-radio-button>
            <b-radio-button
              v-for="category in categoryOptions"
              :key="category"
              v-model="localValue[i].category"
              :native-value="category"
              size="is-small"
            >
              {{ category }}
            </b-radio-button>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-button
            :disabled="i === 0"
            icon-left="trash"
            type="is-danger"
            style="margin-top: 32px"
            outlined
            @click="deleteItem(item.id)"
          />
        </div>
      </div>
      <b-button
        icon-left="plus"
        type="is-info"
        size="is-small"
        rounded
        outlined
        @click="addItem"
      >
        Add
      </b-button>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import gql from 'graphql-tag'

import AutocompleteOrder from '@/components/Autocomplete/Order.vue'
import AutocompleteProduct from '@/components/Autocomplete/Product.vue'
import AutocompleteProductFamily from '@/components/Autocomplete/ProductFamily.vue'

export default {
  components: {
    AutocompleteOrder,
    AutocompleteProduct,
    AutocompleteProductFamily,
  },
  props: {
    tx: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      localValue: JSON.parse(JSON.stringify(this.tx.items)),
      orderId: this.tx.orderId,
      categoryOptions: ['Shipping', 'Banking', 'Secretary', 'Customization', 'Yuzu', 'R&D', 'Samples', 'Software', 'Dividends', 'Taxes', 'Design fees', 'Perso Jerem', 'Perso Simon', 'Office']
    }
  },
  computed: {
    isInternal () {
      return this.localValue.length === 0
    },
    order () {
      if (!this.orderId) return null
      return this.$store.state.orders.find(i => i.id === this.orderId)
    },
    orderData () {
      if (!this.order) return null
      const items = []
      this.order.items.forEach((i) => {
        const product = this.$store.getters.productsById[i.productId]
        items.push({
          amount: i.qty * i.unitPrice,
          productId: i.productId,
          productFamilyId: product ? product.family.id : undefined,
        })
      })
      return items
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler: _.debounce(function () {
        this.saveItems()
      }, 300),
    },
    orderId () {
      this.updateOrderId()
    },
    'tx.items' () {
      this.localValue = JSON.parse(JSON.stringify(this.tx.items))
    },
  },
  methods: {
    async updateFromOrder () {
      await this.localValue.map(item => this.deleteItem(item.id))

      // remove order relation
      if (!this.orderId) {
        await this.addItem()
        this.updateLeftover()
        return
      }

      // bank commissions
      if (this.tx.amount < 0) {
        await this.addItem()
        this.localValue[0].description = ''
        this.localValue[0].category = 'Banking'
        this.localValue[0].productId = null
        this.localValue[0].productFamilyId = null
        this.updateLeftover()
        return
      }
      // actually paid / total due
      const discount = this.order.discountAmount || 0
      const ratio = this.tx.amount / (this.order.amount + discount)
      // create new items
      await Promise.all(this.orderData.map(() => this.addItem()))
      this.orderData.forEach((item, i) => {
        this.localValue[i].amount = item.amount * ratio
        this.localValue[i].amountUsd = this.usdAmount(item.amount) * ratio
        this.localValue[i].productId = item.productId
        this.localValue[i].productFamilyId = item.productFamilyId
      })
      if (this.order.shippingAmount) {
        await this.addItem()
        const i = this.localValue.length - 1
        this.localValue[i].amount = this.order.shippingAmount * ratio
        this.localValue[i].amountUsd = this.order.shippingAmount * ratio
        this.localValue[i].category = 'Shipping'
      }
      this.updateLeftover()
    },
    usdAmount (value) {
      switch (this.currency) {
        case 'USD': return value
        case 'SGD': return value * .7035
      }
      return NaN
    },
    usdAmountMessage (value) {
      const usdValue = this.usdAmount(value)
      if (usdValue === value) return undefined
      return this.$options.filters.$(usdValue, 2)
    },
    updateLeftover () {
      if (this.localValue.length === 0) return
      const leftover = this.localValue.reduce((leftover, item, i) => i > 0
        ? leftover - item.amount
        : leftover
      , Math.abs(this.tx.amount))
      this.localValue[0].amount = Math.max(0, leftover)
    },
    updateProductFamily (i) {
      const productId = this.localValue[i].productId
      if (productId === null) {
        this.localValue[i].productFamilyId = null
        return
      }
      const product = this.$store.getters.productsById[productId]
      if (product && product.family)
        this.localValue[i].productFamilyId = product.family.id
    },
    async updateOrderId () {
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($item: AccountTxInput!) {
            accountTxUpdate(item: $item) { id }
          }`,
          variables: {
            item: {
              id: this.tx.id,
              orderId: this.orderId,
            },
          },
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
      this.updateFromOrder()
    },
    async saveItems () {
      const prepItem = i => ({
        ...i,
        amount: Number(i.amount),
        amountUsd: this.usdAmount(Number(i.amount)),
        __typename: undefined,
      })
      try {
        const variables = {}
        if (this.tx.amount < 0) variables.costs = this.localValue.map(prepItem)
        else variables.incomes = this.localValue.map(prepItem)
        await this.$apollo.mutate({
          mutation: gql`mutation (
            $incomes: [IncomeInput!]
            $costs: [CostInput!]
          ) {
            accountTxItems(
              incomes: $incomes
              costs: $costs
            )
          }`,
          variables,
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    async addItem () {
      try {
        const mutationName = this.tx.amount > 0 ? 'incomeCreateOrUpdate' : 'costCreateOrUpdate'
        const mutationInput = this.tx.amount > 0 ? 'IncomeInput' : 'CostInput'
        const item = this.localValue.length > 0
          ? {
            date: this.localValue[0].date,
            description: '',
            amount: 0,
            amountUsd: 0,
            accountTxId: this.localValue[0].accountTxId,
          } : {
            date: this.tx.date,
            amount: Math.abs(this.tx.amount),
            amountUsd: this.usdAmount(Math.abs(this.tx.amount)),
            accountTxId: this.tx.id,
          }
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation ($item: ${mutationInput}!) {
            ${mutationName}(item: $item) {
              id date description amount amountUsd productId productFamilyId accountTxId
            }
          }`,
          variables: { item },
        })
        this.localValue.push(data[mutationName])
        this.$nextTick(() => {
          this.$refs.amount[this.$refs.amount.length - 1].focus()
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    async deleteItem (id) {
      const mutationName = this.tx.amount > 0 ? 'incomeDelete' : 'costDelete'
      try {
        await this.$apollo.mutate({
          mutation: gql`mutation ($id: ID!) {
            ${mutationName}(id: $id)
          }`,
          variables: { id },
        })
        const index = this.localValue.findIndex(i => i.id === id)
        this.localValue.splice(index, 1)
        this.updateLeftover()
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
        })
      }
    },
    setInternalTransfer (value) {
      if (value)
        this.localValue.forEach(item => this.deleteItem(item.id))
      else
        this.addItem()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
